import React, {useRef, useState} from 'react';
import Modal from 'react-modal';
import './App.css';
import QRCode from "qrcode";

Modal.setAppElement('#root');

const API_PATH = 'https://devel.bigbanda.pl/arwall-form/api/'
const MAX_FILE_SIZE = 10 * 1024 * 1024;


function isMobileDevice() {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
}

const App = () => {
    const [image, setImage] = useState(null);
    const [width, setWidth] = useState(50);
    const [color, setColor] = useState(1);
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fileName, setFileName] = useState('No file chosen');
    const [fileError, setFileError] = useState('');
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const sendMail = async (qr, url) => {
        let data = {
            'qr': qr,
            'url': url,
            'email': email
        };

        try {
            const response = await fetch(`https://devel.bigbanda.pl/arwall-form/api/sendMail.php`, {
                method: 'post',
                mode: 'cors',
                cache: 'no-cache',
                redirect: 'follow',
                body: JSON.stringify(data)
            }).then(r => r.json());

            if (!response?.success) {
                console.error(response);
                return false;
            }
        } catch (err) {
            console.error(err);
            return false;
        }
    }
    const deleteFile = async (filename) => {
        let data = {'image': filename}

        try {
            const fileResponse = await fetch(`https://devel.bigbanda.pl/arwall-form/api/deleteFile.php`, {
                method: 'post',
                mode: 'cors',
                cache: 'no-cache',
                redirect: 'follow',
                body: JSON.stringify(data)
            }).then(r => r.json());

            if (fileResponse?.success) {
                return API_PATH + fileResponse.file;
            } else if (fileResponse?.message) {
                console.error(fileResponse.message);
                setErrorMessage(fileResponse.message);
                return false;
            }

        } catch (err) {
            console.error(err);
            setErrorMessage('An error occurred while deleting file');
            return false;
        }
    }
    const saveFile = async (image) => {
        let formData = new FormData();
        formData.append('image', image);

        try {
            const fileResponse = await fetch(`https://devel.bigbanda.pl/arwall-form/api/uploadFile.php`, {
                method: 'post',
                mode: 'cors',
                cache: 'no-cache',
                redirect: 'follow',
                body: formData
            }).then(r => r.json());

            if (fileResponse?.success) {
                return fileResponse.file;
            } else if (fileResponse?.message) {
                console.error(fileResponse.message);
                setErrorMessage(fileResponse.message);
                return false;
            }

        } catch (err) {
            console.error(err);
            setErrorMessage('An error occurred while processing your file');
            return false;
        }
    }
    const getQR = async (filename) => {
        let data =
            {
                'image': API_PATH + filename,
                'imageWidth': width,
                'imageHeight': (width * dimensions.height)/dimensions.width,
                'frame': color
            }

        try {
            setLoader(true);
            setModalIsOpen(true);
            setErrorMessage('');

            const response = await fetch(`https://devel.bigbanda.pl/ar-wall/api/process.php`, {
                method: 'post',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                body: JSON.stringify(data)
            }).then(r => r.json());

            if (response?.success) {
                setErrorMessage('');
                setLoader(false);

                if (isMobileDevice()) {
                    window.location.href = response.url;
                } else {
                    const canvas = document.getElementById('canvas')

                    QRCode.toCanvas(canvas, response['url'], function (error) {
                        if (error) console.error(error)
                    })

                    QRCode.toDataURL(response['url'], {type: "png"})
                        .then(async img => {
                            await sendMail(img, response.url);
                        })
                }
            } else if (response?.message) {
                setErrorMessage(response.message);
            }
        } catch (err) {
            console.error(err);
            setErrorMessage('An error occurred while processing your request');
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!image) {
            setFileError('Please choose an image file.');
            return;
        }

        let filename = await saveFile(image);
        if (!filename) {
            setFileError(errorMessage);
            return;
        }

        await getQR(filename);
        await deleteFile(filename);
    };


    const handleFileChange = (e) => {
        const file = e.target.files[0];

        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
            setDimensions({ width: img.width, height: img.height });
            URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl;

        if (file && file.size <= MAX_FILE_SIZE) {
            setImage(file);
            setFileName(file ? file.name : 'No file chosen');
            setFileError('');
        } else {
            setFileError('File size exceeds 2 MB.');
        }
    };
    const handleRangeWidthChange = (e) => {
        setWidth(e.target.value);
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Image to display (JPG files only, up to 10 MB):</label>
                    <div className="file-input-wrapper">
                        <label className="file-input" data-text={fileName}>
                            <input type="file" accept=".jpg,.jpeg,.png" onChange={handleFileChange}/>
                        </label>
                        {fileError && <div className="file-error">{fileError}</div>}
                    </div>
                </div>
                <div>
                    <label>Image width in cm:</label>
                    <div className="range-container">
                        <div className="range-value" style={{left: `calc(${width}% - 20px)`}}>{width} cm</div>
                        <input type="range" min="1" max="100" value={width} onChange={handleRangeWidthChange}/>
                    </div>
                </div>
                <div className="select-container">
                    <label>Choose frame color:</label>
                    <select value={color} onChange={(e) => setColor(e.target.value)} required>
                        <option value="">Select color</option>
                        <option value={0}>Black</option>
                        <option value={1}>White</option>
                    </select>
                </div>
                <div>
                    <label>Your e-mail:</label>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                </div>
                <div className="button-submit-container">
                    <button id="ar-btn" type="submit" className="button-submit">
                        Ready to go - see in AR
                        {/*<img src={process.env.PUBLIC_URL + 'arrow-right.svg'} alt="Right arrow" className="arrow"/>*/}
                        <span className="arrow">
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.75 11.75L18.25 11.75M18.25 11.75L14.25 7.75M18.25 11.75L14.25 15.75"
                                      stroke="white" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                            </svg>
                        </span>
                    </button>
                </div>
            </form>

            <Modal className="modal" isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                <div>
                    <div>
                        <div className="modalContent">
                            <h1>See in AR!</h1>
                            <canvas id="canvas"></canvas>
                            {loader && <div className="QrModalLoader"></div>}
                            {loader && <canvas></canvas>}
                            <div style={{color: 'darkred'}}>{errorMessage}</div>
                            <p>Scan the QR code with your smartphone and place a virtual product in your own space.</p>
                            <div className="button-submit-container">
                                <button className="button-submit" onClick={() => setModalIsOpen(false)}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default App;
